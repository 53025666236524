.loader-hidden {
    display: none;
}
.loader-active {
    display: block;
    text-align: center;
}

.loader-inner > div {
    background-color: #bebebe !important;
    /*background-color: #ddd !important;*/
    /* background-color: #3F51B5 !important; */
    /*background-color: #009efb !important;*/
}
