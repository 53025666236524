//** MARKDOWN **//

.markdown-raw,
.markdown-compiled {
  min-height: 5em;
  display: block;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  transition: background-color 0.12s ease-out,  border 0.12s ease-out;
  will-change: background-color, border;
}

.markdown-compiled,
.markdown-message {
  > * {
    margin-bottom: 0.5em;
  }
  ul, ol {
    padding-left: 1.25em;
  }
  p > code {
    color: #D81B60;
    background: hsla(338, 78%, 48%, 0.05);
    padding: 1px 4px;
    border: 1px solid hsla(0, 0%, 0%, 0.1);
    border-radius: 2px;
  }
  pre {
    border: 1px solid #ddd;
    padding: 0.75em;
    background-color: #fafafa;
    > code {
      background: unset;
    }
  }
  blockquote {
    color: #666;
    padding: 0.36em 1em;
    background-color: #fafafa;
    border-left: 0.25em hsl(0, 0%, 75%) solid;
    > :last-child {
      margin-bottom: 0;
    }
  }
  img {
    max-width: 100%;
  }
}

.markdown-compiled {
  border-color: transparent;
  &.markdown-empty {
    background-color: rgba(9,30,66,.06);
  }
  &.markdown-filled {
    // background-color: rgba(9,30,66,.03);
  }
  &.markdown-editor {
    background-color: rgba(9,30,66,.02);
    border-top-left-radius: 0;
    border-color: #777;
    &:not(.markdown-button) {
      cursor: default;
    }
  }
  > :last-child {
    margin-bottom: 0;
  }
}

.markdown-raw {
  display: block;
  background-color: rgba(9,30,66,.03);
  border-color: rgba(9, 30, 66, .18);
  &:hover {
    background-color: rgba(9, 30, 66, .08);
  }
  &::placeholder {
    color: #333;
  }
  &:focus {
    cursor: text;
    outline: none;
    background-color: rgba($focus-color-main, .03);
    border-color: $focus-color-main;
    &::placeholder {
      color: #bebebe;
    }
  }
}

.markdown-button:placeholder-shown,
.markdown-button.markdown-empty,
.markdown-button.markdown-compiled {
  &:hover:not(:focus) {
    cursor: pointer;
    background-color: rgba(9,30,66,.12);
  }
}

.markdown-editor-helpers {
  min-height: 2.5em;
  display: inline-flex;
  vertical-align: bottom;
}

@media (min-width: $breakpoint-xl) {
  #messages-list .markdown-message,
  #compose-message .markdown-editor {
    font-size: .875rem;
  }
}
