:root {
  --focus-color-main: #{$focus-color-main};
  --focus-color-light: #{$focus-color-light};
  --focus-color-secondary: #{$focus-color-secondary};
}

.root-wrap {
  min-height: 100vh;
}
.root-wrap > #root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

//** DROPZONE **//

.dropzone .dz-preview {
  margin: 0;
  box-shadow: none;
  width: 100%;
  min-width: unset;

  .dz-filename {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


//** LIGHTBOX **//

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 8050 !important;
}

.ril__toolbar {
  background: none !important;
}

.ril__toolbarItem {
  background-color: rgba(0, 0, 0, 0.2);
}

//** BUTTONS **//

.more-button-as-card {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.more-button-wrapper {
  padding-top: 80.86%;
}

.btn-badge {
  pointer-events: none;
  cursor: default !important;
}


//** TEXT **//

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-break-word {
  word-break: break-word;
}

.text-transform-unset {
  text-transform: unset;
}


//** OTHER **//

.table-top-caption {
  caption-side: top;
  color: black;
}

.form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 0 transparent, 0 1px 0 $focus-color-main !important;
  border-bottom-color: $focus-color-main !important;
}

.dropdown-item.active {
  background-color: $focus-color-main !important;
}

.d-grid {
  display: grid;
}

.z-index-0 {
  z-index: 0 !important;
}

.wmax-450 {
  max-width: 450px;
}

.wmax-650 {
  max-width: 650px;
}

.wmax-700 {
  max-width: 700px;
}

.header-divider {
  align-items: center;
  display: flex;
  flex: 1;
  &:after {
    content: '';
    border-bottom: 1px solid #00000036;
    margin-right: 12px;
    margin-left: 12px;
    flex-grow: 1;
    flex-basis: 0%;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav > div > .nav-item > .dropdown-menu {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.fake-checkbox {
  content: "\e600";
  font-family: "icomoon";
  font-size: 1rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  line-height: 1;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity ease-in-out 0.15s;
}

.rc-slider-disabled {
  background-color: transparent !important;
}

.rc-slider-disabled .rc-slider-handle, .rc-slider-disabled .rc-slider-dot {
  cursor: default !important;
}

.DateTimeRange {
  border: 1px solid #d9d9d9;
  border-radius: 4px !important;
}

.loader-wrapper {
  margin: 20px auto;
}

.no-border {
  border: none !important;
}

.z-index-1 {
  z-index: 1;
}

.word-break {
  word-break: break-word;
}

.grayscale {
  filter: grayscale(100%);
}

.background-gray {
  background-color: #FAFAFA;
}

.ReactVirtualized__Grid__innerScrollContainer, .ReactVirtualized__Grid, .ReactVirtualized__List{
  overflow: visible !important;
}

.wmax-1200 {
  max-width: 1200px;
}

.wmax-400 {
  max-width: 400px;
}

.attachment-item.bg-pink.selected {
  background-color: #E91E63;
}

.attachment-item.bg-pink.selected:hover {
  background-color: #EC407A;
}

.text-underline {
  text-decoration: underline;
}

.hmin-30 {
  min-height: 30px;
}
