.archi-incoming-message-exist {
  #divRplyFwdMsg,
  #composer_signature,
  #composer_signature ~ div,
  .gmail_quote,
  .gmail_extra,
  .gmail_signature,
  // .alert,
  :empty:not(br):not(div),
  .WordSection1 > p:not(.MsoNormal),
  // :not(.WordSection1) > .MsoNormal,
  // div.content,
  .MsoNormal ~ div:last-of-type
  // & > :not(div):not(p),
  // & > div:not(:first-child):only-of-type:last-child > div:not(:only-of-type):first-child > div:only-child > div:only-child > div:only-child > div:only-child > div:only-child,
  // & > div:not(:first-child):only-of-type:last-child > div:not(:only-of-type):first-child > div:only-child > div:only-child > div font,
  // & > div:only-child > div:not(:only-of-type):last-child:last-of-type > div:not(:only-of-type):first-child:first-of-type > div:only-child > div:only-child > div font,
  // & > div:last-child:last-of-type:not(:only-of-type) > div:first-child:first-of-type,
  // & > p:last-of-type:not(:first-child):not(:first-child),
  // & > div:last-child:last-of-type:not(:only-child) > div > span
  {
    display: none !important;
    visibility: hidden !important;
    position: absolute !important;
    pointer-events: none !important;
  }
  :not(code):not(pre):not(blockquote) {
    font-family: inherit !important;
    color: inherit !important;
  }
  :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: inherit !important;
  }
}