@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900&display=swap');

@import "base/css/vendor/icons/icomoon/styles.scss";
@import "base/css/bootstrap.scss";
@import "base/css/bootstrap_limitless.scss";
@import "base/css/layout.scss";
@import "base/css/components.scss";
@import "base/css/colors.scss";

@import "variables.scss";

@import "attachments.scss";
// @import "dateRangePicker.scss"; // Old styles for react-dates
// @import "dateTimePicker.scss"; // Old custom styles for react-widgets component
// @import "datepicker.scss"; // Old styles for react-dates
@import "reactDateTimePicker.scss";
@import "~react-datepicker/dist/react-datepicker.css";
@import "grid_layout.scss";
@import "markdown.scss";
@import "email_response_cleanup.scss";
//@import "wysiwyg.scss";
@import "reactImageLightbox.scss";

@import "custom.scss";
