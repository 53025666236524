//** ATTACHMENTS **//

.grid-attachments-container--fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  grid-gap: 9px;
}

.grid-message-attachments-container--fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 12px;
}

.attachment-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  transition: opacity 0.12s ease-out;
  will-change: opacity;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    transition: opacity 0.12s ease-out;
    border-radius: inherit;
    will-change: opacity;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.15) 48px, rgba(0, 0, 0, 0.05) 96px, transparent);
    z-index: -1;
  }
  :not(.attachment-overlay-content-static),
  &:after {
    opacity: 0;
    transition: opacity 0.12s ease-out;
    will-change: opacity;
  }
  &:hover:after,
  &:hover :not(.attachment-overlay-content-static) {
    opacity: 1;
  }
}

.message-attachment-actions > li {
  display: none !important;
  .message-attachment-item:hover &,
  .message-attachment-item &.active {
    display: initial !important;
  }
}

.attachment-item {
  background-color: transparent;
  transition: all 0.12s ease-out;
  will-change: background-color, box-shadow;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: hsl(0, 0, 97);
  }
  &.selected {
    background-color: hsl(200, 80, 95);
    &:hover {
      background-color: hsl(200, 70, 93);
    }
  }
}

.card-img-attachment {
  position: relative;
  z-index: 0;
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: auto;
    z-index: 0;
    opacity: 1;
    box-shadow: 0 3px 9px -5px rgba(0,0,0,.18), 0 5px 4px -3px rgba(0,0,0,.15);
    pointer-events: none;
    transition: opacity 0.12s ease-out, box-shadow 0.12s ease-out;
    will-change: box-shadow;
    &.img-shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 99%;
      filter: blur(5px);
      transform: translateY(7.2%) scale(.88);
      z-index: -1;
      .selected & {
        opacity: 0;
      }
    }
    .selected & {
      box-shadow: none;
    }
  }
}

.card-file-attachment {
  position: relative;
  box-shadow: 0 3px 9px -5px rgba(0,0,0,.18), 0 5px 4px -3px rgba(0,0,0,.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.12s ease-out;
  will-change: box-shadow;
  .selected & {
    box-shadow: none;
  }
}

.attachment-backdrop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  transition: opacity 0.12s ease-out;
  will-change: opacity;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.15) 48px, rgba(0, 0, 0, 0.05) 96px, transparent);
  .attachment-item:hover & {
    opacity: 1;
  }
}

.attachment-actions {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.attachment-actions > a,
.attachment-checkbox {
  opacity: 0 !important;
  pointer-events: none;
  transition: opacity 0.12s ease-out;
  will-change: opacity;
  .attachment-item:hover &,
  &.active {
    pointer-events: all;
    opacity: 1 !important;
  }
}

.attachment-checkbox {
  box-sizing: content-box;
  transition: all 0.12s ease-out;
  .selected & {
    opacity: 1 !important;
  }
}
